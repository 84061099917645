// Generated by Framer (2a8efd8)

import { addFonts, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["tKh3G7NVy"];

const serializationHash = "framer-gfqVd"

const variantClassNames = {tKh3G7NVy: "framer-v-zqrmxz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate1 = (_, t) => `perspective(1200px) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "tKh3G7NVy", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.section {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zqrmxz", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"tKh3G7NVy"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1aiy6ih"} data-framer-name={"Image Wrapper"} layoutDependency={layoutDependency} layoutId={"OhWZlTuOm"}><ImageWithFX __framer__adjustPosition={false} __framer__offset={0} __framer__parallaxTransformEnabled __framer__speed={90} __framer__styleTransformEffectEnabled __framer__transformTargets={[{target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, x: 0, y: 0}}, {target: {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 0.9, x: 0, y: 0}}]} __framer__transformTrigger={"onScroll"} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", intrinsicHeight: 436, intrinsicWidth: 635, pixelHeight: 436, pixelWidth: 635, sizes: "min(1200px, 100vw)", src: "https://framerusercontent.com/images/iwa3hk26aJPJ75SRDi8tIjmwCRg.jpeg", srcSet: "https://framerusercontent.com/images/iwa3hk26aJPJ75SRDi8tIjmwCRg.jpeg?scale-down-to=512 512w, https://framerusercontent.com/images/iwa3hk26aJPJ75SRDi8tIjmwCRg.jpeg 635w"}} className={"framer-jv47lh"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"jDpWtAfFG"} transformTemplate={transformTemplate1}/></motion.div></motion.section></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-gfqVd[data-border=\"true\"]::after, .framer-gfqVd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gfqVd.framer-1otrrin, .framer-gfqVd .framer-1otrrin { display: block; }", ".framer-gfqVd.framer-zqrmxz { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-gfqVd .framer-1aiy6ih { aspect-ratio: 1.7777777777777777 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 675px); max-height: calc(var(--framer-viewport-height, 100vh) * 0.9); overflow: hidden; position: relative; width: 100%; }", ".framer-gfqVd .framer-jv47lh { flex: none; height: 120%; left: calc(50.00000000000002% - 100% / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 120% / 2); width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gfqVd.framer-zqrmxz { gap: 0px; } .framer-gfqVd.framer-zqrmxz > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-gfqVd.framer-zqrmxz > :first-child { margin-top: 0px; } .framer-gfqVd.framer-zqrmxz > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 675
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerhGqakCKnD: React.ComponentType<Props> = withCSS(Component, css, "framer-gfqVd") as typeof Component;
export default FramerhGqakCKnD;

FramerhGqakCKnD.displayName = "Full Width Image Section";

FramerhGqakCKnD.defaultProps = {height: 675, width: 1200};

addFonts(FramerhGqakCKnD, [])